import { TFunction } from 'i18next';
import * as Yup from 'yup';

import AccountService from 'state/account/account.services';

import { removeChars } from 'util/string';

export const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
    if (!phoneNumber) {
        return false;
    }
    try {
        const response = await AccountService.validatePhoneNumber().post([removeChars(phoneNumber)]);
        return response.phoneNumberVerifications[0].location?.toLowerCase() === 'united states';
    } catch {
        return false;
    }
};

export const MOBILE_PHONE_SETUP_VALIDATION = (t: TFunction) =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(t('forms.errorMessages.invalidPhoneNumber'))
            .min(14, t('forms.errorMessages.invalidPhoneNumber'))
            .test('is-valid-phone', t('forms.errorMessages.invalidUSNumber'), async function (value) {
                if (!value || (value && removeChars(value).length < 10)) {
                    return true;
                }
                const { path, createError } = this;
                const isValid = await validatePhoneNumber(value);
                if (!isValid) {
                    return createError({ path, message: t('forms.errorMessages.invalidUSNumber') });
                }
                return true;
            })
    });
